










































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { validatePhone, validateSms, checkPhone, validatePass } from '@/assets/js/verification'
@Component
export default class bindPhone extends Vue {
  isAdopt = false // 图片验证是否显示
  btnLoading = false
  isContinue = false
  imgVerCation = {
    captcha_id: '',
    rate: 0
  }
  isShowPas = false
  bindModal = false // 手机号是否绑定提示
  time = 0
  smsTitle = '获取验证码'
  ruleForm = {
    phone: '',
    smsCode: '',
    password: ''
  }
  rules = {
    phone: [{ validator: validatePhone, trigger: 'blur' }],
    smsCode: [{ validator: validateSms, trigger: 'blur' }],
    password: [{ validator: validatePass, trigger: 'blur' }]
  }
  @Watch('ruleForm.phone')
  onChildChanged(val: string, oldVal: string): void {
    if (val !== oldVal) {
      this.isContinue = false
      this.isShowPas = false
      this.ruleForm.password = ''
    }
  }
  blurInpt(): void {
    let check = checkPhone(this.ruleForm.phone)
    if (check.status) {
      this.isExixts()
    }
  }
  // is_exists 验证账号是否注册
  isExixts(): any {
    let row = {
      account: this.ruleForm.phone
    }
    this.$http
      .post(this.$api.is_exists, row)
      .then((res: any) => {
        if (res.data.meta.status_code === 200) {
          this.isShowPas = true // 没有账号
        } else if (res.data.meta.status_code === 410) {
          this.isShowPas = false // 有账号
        } else {
          this.$message.error(res.data.meta.message)
        }
      })
      .catch((error: any) => {
        console.log('err', error)
      })
  }
  // 图片验证
  imgVerification(): void {
    this.$http
      .post(this.$api.captcha_img)
      .then((res: any) => {
        if (res.data.meta.status_code === 200) {
          this.$RVerify.configure({
            album: res.data.data
          })
          this.$RVerify.action((res: any) => {
            if (res) {
              this.isAdopt = true
              this.imgVerCation = res
              this.getSmsCode()
            } else {
              this.isAdopt = false
            }
          })
        } else {
          this.$message.error(res.data.meta.message)
        }
      })
      .catch((error: any) => {
        console.log('err', error)
      })
  }
  submitForm(): void {
    ;(this.$refs['ruleForm'] as vForm)?.validate((valid: any) => {
      if (valid) {
        this.btnLoading = true
        let row = {
          account: this.ruleForm.phone,
          code: this.ruleForm.smsCode,
          platform: this.$route.query.type,
          state: this.$route.query.state,
          name: this.$route.query.name,
          union_id: this.$route.query.union_id,
          open_id: this.$route.query.open_id,
          password: this.ruleForm.password
        }
        this.$http
          .post(this.$api.bind_phone, row)
          .then((res: any) => {
            const msg = res.data.meta.message
            if (res.data.meta.status_code === 200) {
              const token = res.data.data.ticket
              // 设置cookie并跳转页面
              this.$store.commit('setToken', token)
              this.$message.success(msg)
              this.btnLoading = false
              // this.$message.error('绑定成功，即将登录太火鸟')
            } else {
              this.btnLoading = false
              this.$message.error(msg)
            }
          })
          .catch((error: any) => {
            this.btnLoading = false
            console.log('err', error)
          })
      } else {
        return false
      }
    })
  }
  // 检测是否绑定过
  checkAccount(): void {
    if (this.isContinue) {
      this.imgVerification()
    } else {
      let row = {
        account: this.ruleForm.phone,
        platform: this.$route.query.type
      }
      this.$http
        .post(this.$api.bind_verify, row)
        .then((res: any) => {
          if (res.data.meta.status_code === 200) {
            this.imgVerification()
          } else if (res.data.meta.status_code === 201) {
            this.bindModal = true
          } else {
            this.$message.error(res.data.meta.message)
          }
        })
        .catch((error: any) => {
          console.log('err', error)
        })
    }
  }
  // 获取验证码
  getSmsCode(): void {
    let row = {
      phone: this.ruleForm.phone,
      platform: this.$route.query.type,
      captcha_id: this.imgVerCation.captcha_id,
      rate: this.imgVerCation.rate
    }
    this.$http
      .post(this.$api.send_code, row)
      .then((res: any) => {
        if (res.data.meta.status_code === 200) {
          this.time = 60
          this.timer()
          this.$message.success(`验证码已发送至 ${this.ruleForm.phone}。如超时未收到，请重新获取`)
        } else {
          this.$message.error(res.data.meta.message)
        }
      })
      .catch((error: any) => {
        console.log('err', error)
      })
  }
  // 点击获取验证码
  getSms(): void {
    let check = checkPhone(this.ruleForm.phone)
    if (!check.status) {
      this.$message.error(check.message)
    } else {
      this.checkAccount()
    }
  }
  timer(): void {
    if (this.time > 0) {
      this.time--
      this.smsTitle = `${this.time} 秒后重新获取`
      if (!this.time) {
        this.smsTitle = '重新获取'
      }
      setTimeout(this.timer, 1000)
    }
  }
  // 登录
  cancel(): void {
    this.$router.push({ name: 'login' })
  }
  // 继续
  continueBind(): void {
    // this.getSmsCode()
    this.isContinue = true
    this.imgVerification()
    this.bindModal = false
  }
  // 更换手机号
  replacePhone(): void {
    ;(this.$refs['ruleForm'] as vForm)?.resetFields()
    this.bindModal = false
    this.isContinue = false
  }
}
